


import React, { useState } from 'react';
// import RegistrationForm from './RegistrationForm';
import RegistrationForm from './ResistrationForm';
import DealerForm from './DealerForm';


function NewContacts() {
  const [showDealer, setShowDealer] = useState(false)

  const handleClick = () => {
    setShowDealer(true)
  }

  const jobList = [
    {
      title: 'CNC Turner',
      vacancies: 5,
      role: 'We are seeking a skilled and experienced CNC Turner to join our manufacturing team. ',
      description: 'Operate CNC turning machines to produce components as per engineering drawings and specifications.  Set up CNC lathes by installing and adjusting tools, attachments, collets, bushings, and stops.  Load and unload parts and materials from CNC machines. Monitor the machining process, making adjustments as necessary to ensure optimal performance and quality  Perform routine maintenance and troubleshooting of CNC machines to ensure proper functioning.',
      joiningInfo: 'Proven experience as a CNC Turner or similar role in a manufacturing environment.  Proficiency in reading and interpreting engineering drawings and blueprints    Strong understanding of CNC programming and G-code. Excellent attention to detail and precision in machining. Ability to use precision measuring instruments such as micrometers, calipers, and gauges.   Good problem-solving skills and the ability to troubleshoot machining issues.  Strong organizational and time management skills.',
    },
    {
      title: 'Marketing Executive',
      vacancies: 2,
      role: 'We are looking for a dynamic and results-driven Marketing Executive to join our marketing team. .',
      description: 'Develop and execute marketing plans and campaigns to promote our products and services.  Conduct market research to identify target audiences, market trends, and competitive analysis. Create and manage content for marketing materials, including brochures, newsletters, social media posts, and website updates.   Plan and coordinate marketing events, trade shows, and promotional activities.  Monitor and analyze the performance of marketing campaigns, adjusting strategies as necessary to achieve desired outcomes.',
      joiningInfo: 'Bachelors degree in Marketing, Business Administration, or a related field. Proven experience as a Marketing Executive or similar role.  Strong understanding of marketing principles and practices.   Excellent written and verbal communication skills.   Proficiency in using marketing software and tools, such as CRM, email marketing platforms, and analytics tools.  Creative thinking and problem-solving abilities.',
    },
    {
      title: 'Digital Marketing Specialist',
      vacancies: 2,
      role: 'We are seeking a talented and experienced Digital Marketing Specialist to join our team.',
      description: 'This is a full-time remote role for a Digital Marketing Intern. The Digital Marketing Intern will be responsible for assisting with social media marketing, digital marketing, web analytics, online marketing, and communication tasks. The intern will gain hands-on experience in various aspects of digital marketing and contribute to the growth of the companys online presence. We wish to target some companies in Mechanical Industry .',
      joiningInfo: "Social Media Marketing and Online Marketing skill  Digital Marketing and Web Analytics skills  Strong communication skills    Experience with digital marketing tools and platforms is a plus   Knowledge of SEO and content marketing is beneficial Ability to work independently and remotely  Currently pursuing a degree in Marketing, Business, or related field",
    },
  ];

  const [selectedJob, setSelectedJob] = useState(null);

  const handleReadMore = (job) => {
    setSelectedJob(job);
  };

  const handleBack = () => {
    setSelectedJob(null);
  };

  return (
    <div className=" bg-cover "
      style={{
        backgroundImage: `url('https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')`,
      }} >
      <div className='pt-28 pl-24  bg-black/60 flex'>
        <button className={`px-6 py-2 ${showDealer ? "text-white border-green-400 border bg-white-400 " : "text-white bg-green-400"} `} onClick={() => setShowDealer(false)} >Non Dealer Application</button>
        <button className={`px-6 py-2 ${!showDealer ? "text-white border-green-400 border bg-white-400 " : "text-white bg-green-400"} `} onClick={() => setShowDealer(true)}> Dealer Application</button>
      </div>

      {
        showDealer ?
          <div className='bg-black/60'>
            <DealerForm />
          </div>
          :
          <div className='md:grid md:grid-cols-2  bg-black/60'>
            <div className='text-white lg:mx-6 md:mt-20 flex justify-center item-center  '>
              <div className="">
                <h1 className="pt-20 text-2xl font-semibold capitalize lg:text-3xl">
                  Get In Touch With Us
                </h1>
                <p className="mt-6 max-w-xl">
                  For inquiries, partnerships, or to explore how we can
                  collaborate to pioneer innovative solutions in safety and
                  efficiency across diverse industries, please feel free to
                  reach out to us. We value your input and are committed to
                  ensuring seamless integration of advanced technologies to
                  safeguard lives, assets, and operations worldwide.
                </p>


              </div>
            </div>
            <div className='container'>
              <RegistrationForm />
            </div>

          </div>
      }
    </div>
  );
}

export default NewContacts;
