import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
// import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { Link } from "react-router-dom";
import ProductDropdown from "./NavDropdown";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className=" whiteBg mt-0 md:mt-10" style={y > 100 ? { height: "60px", marginTop: "0px" } : { height: "80px" }}>
        <NavInner className="flex  justify-between sm:mx-10 md:mx-0 xl:mx-20 ">
          <Link className="" to="/" >
            <img src="adoni/logo/adoniTech.png" className=" h-14"></img>
            {/* <h1 className="font-bold text-3xl text-blue-800">AdoniTech</h1> */}
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link style={{ padding: "10px 15px" }} to="/" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <Link to="/About" className="semiBold font15 pointer">
              <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                About
              </p>
            </Link>
            <Link to="/Service" className="semiBold font15 pointer">
              <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                Service
              </p>
            </Link>
            <Link to="/" className="semiBold  pointer">
              {/* <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                Products
              </p> */}
              <ProductDropdown />
            </Link>
            <Link to="/facility" className="semiBold font15 pointer">
              <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                Facilities
              </p>
            </Link>
            <Link to="/news-event-blog" className="semiBold font15 pointer">
              <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                News & Blog
              </p>
            </Link>
            <Link to="/3D-Model" className="semiBold font15 pointer">
              <p style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                Downloads
              </p>
            </Link>

            <li className="semiBold font15 pointer">
              <Link to='/contact-us' style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">

            <li className="ml-4">
              <a href='https://calculation.adonitech.co.in/' className=" border hover:text-white rounded-md hover:bg-black bg-blue-600 text-white  border-blue-600" style={{ padding: "10px 15px" }} >
                Go to Shock Absorber calculator
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;

  display: none;
  @media (max-width: 1169px) {
    display: block;

  }
`;
const UlWrapper = styled.ul`
 
  @media (max-width: 1169px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 1169px) {
    display: none;
  }
`;


