import React from 'react';
import RegistrationForm from './ResistrationForm';
import DealerContactForm from './DealerContactForm';

const DealerForm = () => {
    return (
        <div className='md:grid md:grid-cols-2 gap-x-10'>
            <div>
                <div className="max-w-4xl  p-6 text-white shadow-lg pt-10">
                    <h1 className="text-4xl font-bold text-green-200 text-center mb-4">New Distributors Wanted for AdoniTech</h1>
                    <p className="text-center text-lg mb-6">
                    AdoniTech, the market leader in the shock absorber industry with differentiated technologies and qualities,
                        was established in 1993 and manufacturing/exporting the products to various countries.
                        Supplying the right products with high quality is our mission in the market.
                        We are looking for the distributors having the same goals as KOBA has.
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                        <div className="col-span-1">
                            <h2 className="text-xl font-semibold mb-2 text-orange-200">1. Status of Corresponding Regions</h2>
                            <ul className="list-disc list-inside">
                                <li>Western and Eastern Europe</li>
                                <li>North America</li>
                            </ul>
                        </div>

                        <div className="col-span-1">
                            <h2 className="text-xl font-semibold mb-2 text-orange-200">2. Eligibility Criteria</h2>
                            <ul className="list-disc list-inside">
                                <li>Should have minimum three years of experience in the shock absorber industry area and experienced with major brands are preferred</li>
                                <li>Should have a solid infrastructure for sales, marketing and delivery of products to customers</li>
                                <li>Should have a respectable financial background</li>
                            </ul>
                        </div>

                        <div className="col-span-1">
                            <h2 className="text-xl font-semibold mb-2 text-orange-200">3. How to Apply</h2>
                            <ol className="list-decimal list-inside">
                                <li>Please access Q&A page and download application</li>
                                <li>Eligible applicants should hand over application via email only</li>
                            </ol>
                            <p className="mt-2">Please feel free to contact a responsible staff if you have any inquiry</p>
                            <p className="mt-2 text-green-200">E-mail: <a href="mailto:sales@adonitech.co.in" className="underline text-white">sales@adonitech.co.in</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <DealerContactForm />
            </div>
        </div>
    );
};

export default DealerForm;
