import React from 'react'

export default function News() {
    return (
        <div className=' bg-gray-200 '>

            <div class="container mx-auto md:px-6">

                <section class="pb-32 pt-24 text-center">
                    <h2 class=" text-center text-3xl pb-18 font-bold">
                        Latest articles
                    </h2>

                    <div class="grid gap-6 lg:grid-cols-3 xl:gap-x-12">
                        <div class="mb-6 lg:mb-0">
                            <div
                                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] :bg-neutral-700">
                                <div class="flex">
                                    <div
                                        class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg :shadow-black/20"
                                        data-te-ripple-init data-te-ripple-color="light">
                                        <img src="adoni/newsBlog/ask-4.jpg" class="w-full h-80" />
                                        <a href="#!">
                                            <div
                                                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="p-6">
                                    <h5 class="mb-3 text-lg font-bold">shock absorbers for Cranes </h5>

                                    <p class="mb-4 pb-2">
                                        Adonitech has developed capacity to design manufacture and test shock absorbers for Cranes for 325 Kilo Joules capacity.
                                    </p>
                                    <a href="#!" data-te-ripple-init data-te-ripple-color="light"
                                        class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Read
                                        more</a>
                                </div>
                            </div>
                        </div>

                        <div class="mb-6 lg:mb-0">
                            <div
                                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] :bg-neutral-700">
                                <div class="flex">
                                    <div
                                        class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg :shadow-black/20"
                                        data-te-ripple-init data-te-ripple-color="light">
                                        <img src="adoni/newsBlog/2.jpeg" class="w-full h-80" />
                                        <a href="#!">
                                            <div
                                                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="p-6">
                                    <h5 class="mb-3 text-lg font-bold"> Heavy duty Buffers </h5>

                                    <p class="mb-4 pb-2">
                                        The Heavy duty Buffers are damped by a controlled orifices and returns to a original position by removal of load
                                    </p>
                                    <a href="#!" data-te-ripple-init data-te-ripple-color="light"
                                        class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Read
                                        more</a>
                                </div>
                            </div>
                        </div>

                        <div class="mb-6 lg:mb-0">
                            <div
                                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] :bg-neutral-700">
                                <div class="flex">
                                    <div
                                        class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg :shadow-black/20"
                                        data-te-ripple-init data-te-ripple-color="light">
                                        <img src="adoni/newsBlog/blog3.jpg" class="w-full h-80" />
                                        <a href="adoni/newsBlog/blog3.pdf" download>
                                            <div
                                                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="p-6">
                                    <h5 class="mb-3 text-lg font-bold"> औद्योगिक शॉक ऑब्जर्वर </h5>

                                    <p class="mb-4 pb-2">
                                        रेखीय गती किंवा रोटरी गती
                                        परी नावाची ऊर्जा
                                        विविध उद्योगांमध्ये उद्योगपतींचे शोषण
                                        शॉक शोषक आवश्यक आहे....
                                        
                                       
                                    </p>
                                    <a href="adoni/newsBlog/blog3.pdf" download data-te-ripple-init data-te-ripple-color="light"
                                        class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Read
                                        more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </div>
    )
}
