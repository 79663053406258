import React, { useState, useEffect } from 'react';

const Table = ({ data, entriesPerPage, Category ,loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    let filtered = data;
    if (Category !== 'All') {
      filtered = filtered.filter(item => item.Category === Category);
    }
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.ModelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.ProductName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredData(filtered);
  }, [searchTerm, Category, data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const currentData = filteredData.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  return (
    <div className="p-4">
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 p-2 border border-gray-300 rounded"
      />
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr className='bg-indigo-200'>
            <th>ID</th>
            <th>Model</th>
            <th>Product</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody className="bg-white text-center divide-y divide-gray-200">
          {currentData.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.ModelName}</td>
              <td>{item.ProductName}</td>
              <td>
                <a href={item.productFile} download className="text-indigo-500">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M5 20h14v-2H5v2zm7-18l-5.5 5.5h4v7h3v-7h4L12 2z" />
                  </svg>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <div>
          Showing {currentData.length} of {filteredData.length} entries
        </div>
        <div>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`mx-1 px-3 py-1 border rounded ${i + 1 === currentPage ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500'}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
