import React, { useState } from 'react';
import { mdiAbugidaThai, mdiAccountOutline, mdiEmailOutline, mdiFilePdfBox, mdiListBox, mdiLockOutline, mdiPhone, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';

const DealerContactForm = () => {

    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [isSending, setIsSending] = useState(false);
    const [formError, setFormError] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileURL, setFileURL] = useState("");
    const [loading, setLoading] = useState(false);
    const [toSend, setToSend] = useState({
        username: "",
        email: "",
        phone: "",
        ParentBrands: "",
        TeamSize: "",
        webUrl: "",
        message: ""
    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setFileName(file.name);
            setFileURL(URL.createObjectURL(file));
        } else {
            setFileName("");
            setFileURL("");
        }
    };

    const handleFileRemove = () => {
        setFileName("");
        setFileURL("");
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = "";
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const requiredFields = ["username", "email", "phone", "message"];
        const hasEmptyField = requiredFields.some((field) => !toSend[field]);
        if (hasEmptyField) {
            setFormError("Please fill all fields.");
            return;
        }

        setIsSending(true);
        setFormError(null);
      
        const data = new FormData();
        data.append("username", toSend.username);
        data.append("email", toSend.email);
        data.append("phone", toSend.phone);
        data.append("ParentBrands", toSend.ParentBrands);
        data.append("TeamSize", toSend.TeamSize);
        data.append("webUrl", toSend.webUrl);
        data.append("message", toSend.message);
        if (fileName && fileURL) {
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput && fileInput.files[0]) {
                data.append("file", fileInput.files[0]);
            }
        }

        try {
            setLoading(true);
            const response = await fetch(
                "https://calculation.cranebuffer.com/api/data/dealerForm",
                {
                    method: "POST",
                    body: data,
                },
            );

            if (response.ok) {
                const result = await response.json();
                toast.success(result.msg);

                setToSend({
                    username: "",
                    email: "",
                    phone: "",
                    ParentBrands: "",
                    TeamSize: "",
                    webUrl: "",
                    message: ""
                });
                handleFileRemove();
            } else {
                const errorResult = await response.json();
                throw new Error(errorResult.msg || "Failed to send message");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("Failed to send message");
        } finally {
            setLoading(false);
            setIsSending(false);
        }
    };




    return (
        <div className="min-w-screen min-h-screen  flex items-center justify-center  px-5 py-5">
            <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{ maxWidth: '1200px' }}>
                <div className="md:flex w-full">
                    <div className="w-full  py-10 px-5 md:px-10">
                        <div className="text-center mb-10">
                            <h1 className="font-bold text-3xl text-gray-900">Get In Touch With Us</h1>
                            <p>Enter your information to Fill Form</p>
                        </div>
                        <form className='mx-auto' onSubmit={onSubmit}>
                            <div className="flex -mx-3">
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="firstName" className="text-xs font-semibold px-1">Full name</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiAccountOutline} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            value={toSend.username}
                                            name="username"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            placeholder="Enter your name"
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                        />
                                    </div>
                                </div>
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="Email" className="text-xs font-semibold px-1">Email</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiEmailOutline} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="email"
                                            name="email"
                                            value={toSend.email}
                                            autoComplete="off"
                                            onChange={handleChange}
                                            placeholder="Enter mail id"
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex -mx-3">
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="phone" className="text-xs font-semibold px-1">Phone</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiPhone} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            value={toSend.phone}
                                            name="phone"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            placeholder="Enter mobile number"
                                        />
                                    </div>
                                </div>
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="Parent Brands" className="text-xs font-semibold px-1">Parent Brands</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiListBox} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            name="ParentBrands"
                                            value={toSend.ParentBrands}
                                            autoComplete="off"
                                            onChange={handleChange}
                                            placeholder="Enter Parent Brands Name"
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex -mx-3">
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="TeamSize" className="text-xs font-semibold px-1">Marketing Team Size</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiAccountOutline} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="number"
                                            value={toSend.TeamSize}
                                            name="TeamSize"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            placeholder="Size of Marketring team"
                                        />
                                    </div>
                                </div>
                                <div className="w-1/2 px-3 mb-5">
                                    <label htmlFor="Parent Brands" className="text-xs font-semibold px-1">URL</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiWeb} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="text"
                                            name="webUrl"
                                            value={toSend.webUrl}
                                            autoComplete="off"
                                            onChange={handleChange}
                                            placeholder="Enter url of your website"
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="flex -mx-3">
                                <div className="w-full px-3 mb-5">
                                    <label htmlFor="Message" className="text-xs font-semibold px-1">Message</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none pl-4  py-2">
                                            <Icon path={mdiListBox} size={1} className="text-gray-400" />
                                        </div>
                                        <textarea
                                            value={toSend.message}
                                            name="message"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            placeholder="Enter message"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex -mx-3">
                                <div className="w-full px-3 mb-5">
                                    <label htmlFor="file" className="text-xs font-semibold px-1">Attche Profile</label>
                                    <div className="flex">
                                        <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                            <Icon path={mdiFilePdfBox} size={1} className="text-gray-400" />
                                        </div>
                                        <input
                                            type="file"
                                            className="w-full bg-white -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                            placeholder=""
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={`mt-6 w-full transform rounded-md bg-blue-600 px-6 py-3 text-sm font-medium capitalize tracking-wide text-white transition-colors duration-300 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50 hover:bg-blue-500 ${loading
                                    ? "bg-green-500 focus:ring-green-300 hover:bg-green-600"
                                    : "bg-blue-600 focus:ring-blue-400 hover:bg-blue-500"
                                    }`}
                                disabled={loading}
                            >
                                {loading && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 100 101"
                                        className="mr-3 inline h-4 w-4 animate-bounce text-white"
                                        role="status"
                                        aria-hidden="true"
                                    >
                                        <circle
                                            fill="#34D399"
                                            r="45"
                                            cy="50"
                                            cx="50"
                                        ></circle>
                                    </svg>
                                )}
                                {loading ? "Sending..." : "Get in Touch"}
                            </button>

                            {/* <div className="flex -mx-3">
                <div className="w-full px-3 mb-5">
                  <button type='submit' className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
                    Apply Now
                  </button>
                </div>
              </div> */}
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DealerContactForm;
